import axios from 'axios';
import { axiosInterceptorHelper } from './interceptor';
import settings from '../../settings/settings';

export const webApi = settings.webApi;
export const storePKApi = settings.storePKApi;
export const sendPkByEmailApi = settings.sendPkByEmailApi;
export const walletExplorer = settings.walletExplorer;
export const bwsInstanceUrl = settings.bwsInstanceUrl;

const newAxiosInstance = axios.create({
    baseURL: webApi,
    headers: { 'Content-Type': 'application/json' },
    timeout: 1200000,
});

axiosInterceptorHelper(newAxiosInstance);

export const axiosInstance = newAxiosInstance;

const newNoInterceptorAxiosInstance = axios.create({
    baseURL: webApi,
    headers: { 'Content-Type': 'application/json' },
    timeout: 1200000,
});

export const noInterceptorAxiosInstance = newNoInterceptorAxiosInstance;
