import { get, post, put } from '../utils/axios/https';

export default {
    getFmiPatients: async () => get(`api/paciente`),
    getPatient: async (id) => get(`api/paciente/${id}`),
    updatePatient: async (patient) => put(`api/paciente/${patient.id}/`, patient),
    addPatient: async (patient) => post('api/paciente/', patient),
    getContacts: async (id) => get(`api/pacienteacceso/${id}/`),
    getFmiOptions: async () => get(`api/equipo`),
    getPatientOptions: async () => get(`api/equipo`),
    getAuditorOptions: async () => get(`api/grupoauditor/`),
    getDrugstoreOptions: async () => get(`api/drogueria/`),

    getStudies: () => get(`api/estudio/`),
    getDiagnoses: () => get(`api/diagnostico/`),
    getDoctors: () => get(`api/medico/`),
    getInstitutions: () => get(`api/institucion/`),
    getSocialCoverages: () => get(`api/os`),
    getPrograms: () => get(`api/programa/`),
    getFunders: () => get(`api/financiador`),
    getOperators: () => get(`api/operador`),
    getFileToUploadUrl: (id, name) => get(`api/pacienteestudio/${id}/adjunto/${name}/uploadurl`),
    getFileToS3: (url) => get(url, true),
    /* Add:
        POST {{url}}/api/paciente/acceso/add/364/
        body: {
            "paciente": 364,
            "csee": "gfdgsdfvscvsdcdafd",
            "drogueria": 6,
            "owner": false
        }
    */
    addPatientAccess: (patientId, encryptedAccessData) =>
        post(`api/paciente/acceso/add/${patientId}/`, encryptedAccessData),
    /* Delete:
        POST {{url}}/api/paciente/acceso/delete/354/
        {
            "paciente": 364,
            "drogueria": 6
        } 
    */
    deletePatientAccess: (accessId, body) => post(`api/paciente/acceso/delete/${accessId}/`, body),
};
