import {
    UPDATE_USER,
    SAVE_KEYS,
    VALIDATE_PASSWORD,
    SAVE_PRIVATE_KEY,
    CHANGE_PASSWORD,
    VALIDATE_PRIVATE_KEY,
    SEND_PRIVATE_KEY_BY_EMAIL,
    SAVE_PK_TO_CLOUD,
    UPDATE_PUBLIC_KEY,
    VALIDATE_OLD_PRIVATE_KEY,
    IMPORT_OLD_PRIVATE_KEY,
    UPDATE_MIGRATED_STATUS,
} from './profile.actions';
import { ACCEPT_TERMS_AND_CONDITIONS } from '../common/common.actions';
import { services } from './profile.services';
import { services as sessionServices } from '../session/session.services';
import actions from '../../src/actions';
import { I18n } from 'react-redux-i18n';
import { UserTypes, UserTypesGroups } from '../constants';
import { encryptString, getPublicKeyFromPrivate } from '../utils/encryption';
import { UserGroupMap } from '../utils/maps';
import { KeyManager } from 'jasper-roche-crypto';
import { _saveToken } from '../utils/axios/interceptor';

const profileMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        switch (action.type) {
            case UPDATE_USER: {
                /*
                 * Due to the request is different depending of the user type, here we only handle the
                 * update user action of the OS. The update user action of the user
                 * Register will be handle on the registrar middleware.
                 * */
                let userType = getState().profile.user.type;
                const group = UserGroupMap[userType];

                if (group === UserTypesGroups.OS_USER_GROUP) {
                    updateUser(services.updateUser, action, dispatch);
                }

                if (group === UserTypesGroups.AUDIT_USER_GROUP) {
                    updateUser(services.updateAuditor, action, dispatch);
                }

                if (group === UserTypesGroups.DRUGSTORE_USER_GROUP) {
                    updateUser(services.updateDrugstoreUser, action, dispatch);
                }
                break;
            }
            case SAVE_KEYS:
                /*
                 * Due to the request is different depending of the user type, here we only handle the
                 * save keys action of the OS (without public key). The save keys action of the user
                 * Registrar (without public key) will be handle on the registrar middleware.
                 * */
                if (
                    getState().profile.user.type === UserTypes.OS_USER_WITHOUT_PUBLIC_KEY ||
                    getState().profile.user.type === UserTypes.OS_USER_WITHOUT_OLD_PRIVATE_KEY
                ) {
                    saveKeysType(
                        services.updateOS,
                        getState().session.os_id,
                        action,
                        dispatch,
                        getState().session.directPayment,
                    );
                }
                if (
                    getState().profile.user.type === UserTypes.AUDITOR_WITHOUT_PUBLIC_KEY ||
                    getState().profile.user.type === UserTypes.AUDITOR_WITHOUT_OLD_PRIVATE_KEY
                ) {
                    saveKeysType(
                        services.updateAuditPublicKey,
                        getState().profile.user.audit_group.id,
                        action,
                        dispatch,
                    );
                }
                if (getState().profile.user.type === UserTypes.DRUGSTORE_WITHOUT_PUBLIC_KEY) {
                    saveKeysType(
                        services.updateDrugstorePublicKey,
                        getState().session.drug_id,
                        action,
                        dispatch,
                    );
                }
                break;
            case UPDATE_PUBLIC_KEY:
                switch (UserGroupMap[getState().profile.user.type]) {
                    case UserTypesGroups.OS_USER_GROUP:
                        saveKeysType(services.updateOS, getState().session.os_id, action, dispatch);
                        break;
                    case UserTypesGroups.AUDIT_USER_GROUP:
                        saveKeysType(
                            services.updateAuditPublicKey,
                            getState().profile.user.audit_group.id,
                            action,
                            dispatch,
                        );
                        break;
                    case UserTypesGroups.DRUGSTORE_USER_GROUP:
                        saveKeysType(
                            services.updateDrugstorePublicKey,
                            getState().session.drug_id,
                            action,
                            dispatch,
                        );
                        break;
                }
                break;
            case UPDATE_MIGRATED_STATUS:
                services
                    .updateOS(getState().session.os_id, { migrated_nexus2: true })
                    .then((response) => {
                        dispatch(actions.session.updateMigratedStatusResponse(response));
                    })
                    .catch((e) => dispatch(actions.session.updateMigratedStatusError(e)));
                break;
            case VALIDATE_PASSWORD:
                // note a login is made to validate the user password
                sessionServices
                    .login(getState().profile.user.username, action.password)
                    .then((response) => {
                        _saveToken({ token: response.access, refreshToken: response.refresh });
                        dispatch(actions.profile.validatePasswordResponse());
                    })
                    .catch((error) => {
                        let errorMessage;
                        if (error.status === 401)
                            errorMessage = I18n.t('errors.profile.WRONG_PASSWORD');
                        else errorMessage = I18n.t('errors.generic');
                        dispatch(actions.profile.validatePasswordError(errorMessage));
                    });
                break;
            case SAVE_PRIVATE_KEY:
                if (!getState().session.pk_backup)
                    dispatch(actions.profile.savePKToCloud(action.mnemonic));

                if (getState().profile.user.type === UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY)
                    dispatch(
                        actions.profile.updateUserResponse({
                            user: { type: UserTypes.ROCHE_USER },
                        }),
                    );

                if (
                    getState().profile.user.type === UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY ||
                    getState().profile.user.type === UserTypes.ROCHE_USER
                )
                    dispatch(actions.session.getIsWhiteLister());

                break;
            case IMPORT_OLD_PRIVATE_KEY: {
                const manager = new KeyManager(getState().profile.user.username, action.mnemonic);
                const fundsKey = manager.deriveFundsKey();
                const privateManagerKey = manager.deriveDataKey();
                const publicManagerKey = manager.getPublicKey(privateManagerKey);
                dispatch(actions.profile.saveOldPrivateKey(action.oldPrivateKey));
                dispatch(
                    actions.profile.saveKeys(
                        { private: privateManagerKey, public: publicManagerKey },
                        manager.getAddress(manager.getPublicKey(fundsKey)),
                        manager.mnemonic,
                    ),
                );
                break;
            }
            case CHANGE_PASSWORD:
                services
                    .changePassword(createChangePasswordBody(action))
                    .then((response) => {
                        dispatch(actions.profile.changePasswordResponse(response));
                    })
                    .catch((error) => {
                        dispatch(
                            actions.profile.changePasswordError(
                                translateChangePasswordError(error.data),
                            ),
                        );
                    });
                break;
            case VALIDATE_PRIVATE_KEY: {
                const pubKeyData = getPublicKeyFromSession(getState().profile.user.type, getState);

                const publicKeyDataFromPrivate = action.keyManager.getPublicKey(action.privateKey);
                if (pubKeyData === publicKeyDataFromPrivate) {
                    dispatch(actions.profile.validatePrivateKeyResponse());
                } else dispatch(actions.profile.validatePrivateKeyError('NOT_MATCH'));
                break;
            }
            case VALIDATE_OLD_PRIVATE_KEY: {
                const publicKey = getPublicKeyFromSession(getState().profile.user.type, getState);
                const publicKeyFromPrivate = getPublicKeyFromPrivate(action.oldPrivateKey);
                if (publicKey === publicKeyFromPrivate) {
                    dispatch(actions.profile.validatePrivateKeyResponse());
                } else dispatch(actions.profile.validatePrivateKeyError('NOT_MATCH'));
                break;
            }
            case SAVE_PK_TO_CLOUD:
                if (
                    getState().session?.email_recover_key ||
                    getState().profile.user?.audit_group?.email_recover_key
                ) {
                    const group = UserGroupMap[getState().profile?.user?.type];
                    services
                        .savePKToCloud(
                            createSavePKToCloudBody(
                                getState().profile.user.email,
                                getState().session?.email_recover_key ||
                                    getState().profile.user?.audit_group?.email_recover_key,
                                getState().profile.user.id.toString(),
                                encryptString(
                                    action.mnemonic,
                                    group === UserTypesGroups.AUDIT_USER_GROUP
                                        ? getState().profile.user.audit_group.encryption_key
                                        : getState().session.encKey,
                                ),
                            ),
                        )
                        .then(() => {
                            if (!getState().session.pk_backup) {
                                updatePrivateKey(group);
                            }
                            dispatch(actions.profile.savePKToCloudResponse());
                        })
                        .catch((error) => {
                            dispatch(actions.profile.savePKToCloudError(error.data));
                        });
                    break;
                }
                break;
            case SEND_PRIVATE_KEY_BY_EMAIL: {
                const group = UserGroupMap[getState().profile?.user?.type];
                const recoveryEmail =
                    group === UserTypesGroups.AUDIT_USER_GROUP
                        ? getState().profile.user.audit_group.email_recover_key
                        : getState().session.email_recover_key;
                services
                    .sendPrivateKeyByEmail(
                        getState().session.pk_backup
                            ? getState().profile.user.email
                            : recoveryEmail,
                    )
                    .then(() => {
                        if (!getState().session.pk_backup) {
                            updatePrivateKey(group);
                        }
                        dispatch(actions.profile.sendPrivateKeyByEmailResponse());
                    })
                    .catch((error) => {
                        dispatch(actions.profile.sendPrivateKeyByEmailError(error.data));
                    });
                break;
            }
            case ACCEPT_TERMS_AND_CONDITIONS: {
                /*
                 * Due to the request is different depending of the user type, here we only handle the
                 * accept terms and conditions action of the OS. The accept terms and conditions action action of the user
                 * Register will be handle on the registrar middleware.
                 * */
                const userType = getState().profile.user.type;
                const group = UserGroupMap[userType];

                const updateUserType = (serviceFunction) => {
                    serviceFunction(createAcceptTermsAndConditionsBody())
                        .then(() => {
                            dispatch(actions.common.acceptTermsAndConditionsResponse());
                        })
                        .catch((error) =>
                            dispatch(actions.common.acceptTermsAndConditionsError(error)),
                        );
                };

                if (group === UserTypesGroups.OS_USER_GROUP) {
                    updateUserType(services.updateUser);
                }
                if (group === UserTypesGroups.AUDIT_USER_GROUP) {
                    updateUserType(services.updateAuditor);
                }
                if (group === UserTypesGroups.DRUGSTORE_USER_GROUP) {
                    updateUserType(services.updateDrugstoreUser);
                }

                break;
            }
        }
    };

const getPublicKeyFromSession = (userType, getState) => {
    if (
        userType === UserTypes.OS_USER_WITHOUT_PRIVATE_KEY ||
        userType === UserTypes.OS_USER_WITHOUT_OLD_PRIVATE_KEY
    ) {
        return getState().session.os_publicKey;
    } else if (userType === UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY) {
        return getState().profile.user.audit_group.pubkey_data;
    } else if (userType === UserTypes.AUDITOR_WITHOUT_OLD_PRIVATE_KEY) {
        return getState().profile.user.audit_group.pubkey;
    } else if (userType === UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY) {
        return getState().session.drug_publicKey;
    } else if (userType === UserTypes.REGISTER_USER_WITHOUT_PRIVATE_KEY) {
        return getState().registrar.branch.publicKey;
    }
};

const updatePrivateKey = (group) => {
    const body = { privkey_backup: true };
    switch (group) {
        case UserTypesGroups.OS_USER_GROUP:
            services.updateUser(body);
            break;
        case UserTypesGroups.AUDIT_USER_GROUP:
            services.updateAuditor(body);
            break;
        case UserTypesGroups.DRUGSTORE_USER_GROUP:
            services.updateDrugstoreUser(body);
    }
};

const updateUser = (update, action, dispatch) => {
    update(createUpdateUserBody(action.user))
        .then(() => {
            // note: we return the action user because response is outdated
            dispatch(
                actions.profile.updateUserResponse({
                    user: {
                        email: action.user.email,
                        first_name: action.user.name,
                        last_name: action.user.lastName,
                        phone: action.user.phone,
                    },
                }),
            );
        })
        .catch((error) => dispatch(actions.profile.updateUserError(error)));
};

const saveKeysType = (update, id, action, dispatch, notIncludeAddress) => {
    update(
        id,
        notIncludeAddress
            ? { pubkey_data: action.keys.public }
            : { pubkey_data: action.keys.public, address: action.address },
    )
        .then(() => {
            dispatch(actions.profile.saveKeysResponse({ privateKey: action.keys.private }));
            // and save it to the cloud
            dispatch(actions.profile.savePKToCloud(action.mnemonic));
        })
        .catch((error) => {
            dispatch(actions.profile.saveKeysError(error));
        });
};

const createUpdateUserBody = (user) => ({
    user: {
        first_name: user.name,
        last_name: user.lastName,
        email: user.email,
    },
    telefono: user.phone,
});

const createChangePasswordBody = (action) => ({
    current_password: action.currentPassword,
    new_password: action.newPassword,
    re_new_password: action.repeatNewPassword,
});

const createAcceptTermsAndConditionsBody = () => ({
    acepto_tyc: true,
});

const createSavePKToCloudBody = (userEmail, companyEmail, userId, mnemonic) => ({
    useremail: userEmail,
    cpnyemail: companyEmail,
    nexid: userId,
    data: mnemonic,
});

const translateChangePasswordError = (error) => {
    try {
        const errorKeys = Object.keys(error);
        const firstErrorKey = errorKeys[0];
        const firstErrorArray = error[`${firstErrorKey}`];
        const errorMessage = firstErrorArray[0];

        // Here we are catching some know error that are not translated
        switch (errorMessage) {
            case 'Invalid password.':
                return I18n.t('errors.profile.WRONG_PASSWORD');
            case 'PASSWORD_MISMATCH_ERROR':
                return I18n.t('errors.profile.PASSWORD_MISMATCH');
            default:
                return errorMessage;
        }
    } catch (e) {
        return I18n.t('errors.generic');
    }
};

export default profileMiddleware;
