import { get, post, put } from '../utils/axios/https';
export const services = {
    registrarSignUp: (body) => post('auth/users/', body),
    getInstitutionByCuit: (cuit) => get(`api/institucion?cuit=${cuit}`),
    createInstitution: (body) => post('api/institucion/', body),
    createBranch: (body) => post('api/sucursal/', body),
    getBranchByInstitutionId: (institutionId) => get(`api/institucion/${institutionId}/sucursal`),
    createRegistrar: (body) => post('api/registrador/', body),
    updateBranchPubKey: (branchId, body) => put(`api/sucursal/${branchId}/`, body),
    // todo this route might be wrong
    updateRegistrar: (body) => put(`api/usuarioregistrador/me/`, body, {}),
    activateRegistrarAccount: (uid, token) => post(`auth/users/activation/`, { uid, token }),
};
