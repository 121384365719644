import { get, post, put } from '../utils/axios/https';

export default {
    getContracts: () => get(`api/contrato`),
    getContract: (id) => get(`api/contrato/${id}`),
    getContractOs: (id) => get(`api/paciente/contrato/${id}`),
    putContract: (cont) => put(`api/contrato/${cont.id}/`, cont),
    postContract: (cont) => post(`api/contrato/`, cont),

    getAuditorGroups: () => get(`api/grupoauditor`),

    getFamilyProducts: (prod) => get(`api/familiaprod/${prod}/productos`),
    getContractTypes: () => get(`api/tipocontrato`),
};
