import React from 'react';
import './BenefitsTable.scss';
import { I18n } from 'react-redux-i18n';

export interface ContractData {
    contrato: string;
    familia_producto: string;
    tipo_contrato: string;
    presentaciones: {
        beneficio: string;
        presentacion: string;
        tipo_beneficio: string;
    }[];
}

export const BenefitsTable = ({ data }: { data: ContractData[] }) => {
    const prefixBenefitsTable = 'dashboard.roche.benefits';
    return (
        <>
            <table className='GreyTable-CustomBenefits'>
                <thead>
                    <tr>
                        <th className=''>
                            {I18n.t(`${prefixBenefitsTable}.header.productFamily`)}
                        </th>
                        <th className=''>{I18n.t(`${prefixBenefitsTable}.header.contract`)}</th>
                        <th className=''>{I18n.t(`${prefixBenefitsTable}.header.contractType`)}</th>
                        <th className=''>{I18n.t(`${prefixBenefitsTable}.header.presentation`)}</th>
                        <th className=''>{I18n.t(`${prefixBenefitsTable}.header.benefitType`)}</th>
                        <th className=''>{I18n.t(`${prefixBenefitsTable}.header.benefit`)}</th>
                    </tr>
                </thead>
                {data.map((contractDataItem, i) => {
                    const rowSpan = contractDataItem.presentaciones.length;
                    return (
                        <tbody
                            key={`tbody-contract-item-${i}`}
                            className='GreyTable-CustomBenefits'
                        >
                            {contractDataItem.presentaciones?.map((presentation, i) => {
                                return (
                                    <tr className='tbodyRest' key={`benefit-group-row-${i}`}>
                                        {i === 0 && (
                                            <>
                                                <td rowSpan={rowSpan}>
                                                    {contractDataItem.familia_producto}
                                                </td>
                                                <td rowSpan={rowSpan}>
                                                    {contractDataItem.contrato}
                                                </td>
                                                <td rowSpan={rowSpan}>
                                                    {contractDataItem.tipo_contrato}
                                                </td>
                                            </>
                                        )}
                                        <td>{presentation.presentacion}</td>
                                        <td>{presentation.tipo_beneficio}</td>
                                        <td>{presentation.beneficio}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    );
                })}
            </table>
        </>
    );
};
