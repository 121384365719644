import { post, get } from '../utils/axios/https';

export const services = {
    login: (username, password) =>
        post('auth/jwt/create/', { username: username, password: password }, {}, true),
    restorePassword: (email) => post('auth/users/reset_password/', { email }, { noAuth: true }),
    resetPassword: (uid, token, password, passwordRepeat) =>
        post(
            'auth/users/reset_password_confirm/',
            { uid, token, new_password: password, re_new_password: passwordRepeat },
            { noAuth: true },
        ),
    getUser: () => get('api/usuario/me/'),
    getNotifications: () => get('api/menunotifications/'),
};
