const ESCAPED_CHARACTERS = ['=', '+', '-', '@'];

export const escapeChar = (s?: any) => {
    if (!s) return '';
    let result = s.toString();
    ESCAPED_CHARACTERS.forEach((c) => {
        result = result.replaceAll(c, `'${c}`);
    });
    return result;
};

export const numberToCurrency = (number: number) => {
    const newn = new Intl.NumberFormat('es-AR', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(number);
    return newn;
};
