import { get, post, put } from '../utils/axios/https';

export default {
    getIndications: (ind) => get(`api/indicacion/${ind ?? ''}`),
    putIndication: (ind) => put(`api/indicacion/${ind.id}/`, ind),
    postIndication: (ind) => post(`api/indicacion/`, ind),

    getProdFamily: () => get(`api/familiaprod/`),
    getFamilyProducts: (fam) => get(`api/familiaprod/${fam}/productos`),
    getEvidenceType: () => get(`api/tipoevidencia/`),
};
