import { sendPkByEmailApi, storePKApi } from '../utils/axios/axiosInstance';
import { axios_post, basic_get, post, put } from '../utils/axios/https';

export const services = {
    updateUser: (body) => put(`api/usuarioos/me/`, body, {}),
    updateAuditor: (body) => put(`api/usuarioauditor/me/`, body, {}),
    updateDrugstoreUser: (body) => put(`api/usuariodrogueria/me/`, body, {}),
    updateOS: (os_id, body) => put(`api/os/${os_id}/`, body, {}),
    updateAuditPublicKey: (id, body) => put(`api/auditoria/grupoauditor/${id}/`, body, {}),
    updateDrugstorePublicKey: (id, body) => put(`api/drogueria/${id}/`, body, {}),
    changePassword: (body) => post('auth/users/set_password/', body),
    savePKToCloud: (body) =>
        axios_post(storePKApi, body, { 'x-api-key': `${process.env.REACT_APP_PK_BACKUP_API_KEY}` }),
    sendPrivateKeyByEmail: (email) =>
        basic_get(sendPkByEmailApi + email, {
            'x-api-key': `${process.env.REACT_APP_PK_BACKUP_API_KEY}`,
        }),
};
