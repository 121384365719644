import {
    GET_IS_WHITE_LISTER,
    GET_NOTIFICATIONS,
    LOGIN_MIGRATION_REQUEST,
    LOGIN_REQUEST,
    LOGOUT,
    RESET_PASSWORD,
    RESTORE_PASSWORD,
} from './session.actions';
import actions from '../actions';
import { services } from './session.services';
import { push } from 'connected-react-router';
import { I18n } from 'react-redux-i18n';
import { UserTypes } from '../constants';
import keysStorage from '../utils/storage/keys';
import { parseInstitutionType } from '../utils';
import * as bip39 from 'bip39';
import { KeyManager } from 'jasper-roche-crypto';
import service from '../transaction/transaction.services';
import { getKeysFromKeystore } from '../utils/wallet';
import { _clearToken, _saveToken } from '../utils/axios/interceptor';

const sessionMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        switch (action.type) {
            case LOGIN_REQUEST:
                userLoginService(action.email, action.password, dispatch)
                    .then((userResponse) => {
                        if (userResponse) {
                            // if the response does not return the user on the 'user' prop, added it manually. This
                            // happens when a registrar user has logged in without creating a institution
                            if (!userResponse?.user) userResponse['user'] = { ...userResponse };
                            const normalizedResponse = normalizeUserResponse(userResponse);
                            // compute the user type based on user keys.
                            computeUserType(
                                normalizedResponse.userType,
                                normalizedResponse.pubKey,
                                normalizedResponse.pubKeyData,
                                normalizedResponse.user.username,
                                resolveUserType,
                                normalizedResponse.branchPubkey,
                            )
                                .then(({ userType, privateKey }) => {
                                    dispatch(
                                        actions.session.loginResponse(
                                            createLoginResponse(userResponse, userType, privateKey),
                                        ),
                                    );
                                    if (userType === UserTypes.ROCHE_USER) {
                                        dispatch(actions.session.getIsWhiteLister());
                                    }
                                    if (userType === UserTypes.DRUGSTORE) {
                                        dispatch(actions.patient.getPatients());
                                    }
                                    dispatch(actions.session.getNotifications());
                                    if (
                                        userType === UserTypes.OS_USER ||
                                        userType === UserTypes.AUDITOR ||
                                        userType === UserTypes.DRUGSTORE
                                    ) {
                                        const keyManager = new KeyManager(
                                            normalizedResponse.user.username,
                                        );
                                        if (!normalizedResponse.privateKeyBackup) {
                                            dispatch(
                                                actions.profile.savePKToCloud(keyManager.mnemonic),
                                            );
                                        }
                                    }
                                })
                                .catch(() => {
                                    dispatch(
                                        actions.session.loginResponseError(translateError('')),
                                    );
                                });
                        }
                    })
                    .catch((error) => {
                        dispatch(
                            actions.session.loginResponseError(translateError(error.data.detail)),
                        );
                    });
                break;
            case LOGIN_MIGRATION_REQUEST:
                userLoginService(action.email, action.password, dispatch)
                    .then((userResponse) => {
                        if (userResponse) {
                            // if the response does not return the user on the 'user' prop, added it manually. This
                            // happens when a registrar user has logged in without creating a institution
                            if (!userResponse?.user) userResponse['user'] = { ...userResponse };
                            const normalizedResponse = normalizeUserResponse(userResponse);
                            // compute the user type based on user keys.
                            // Check for os 4. This code is executed when REACT_APP_TEST_MIGRATION_WITH_OS_4 flag is active. Add if necessary
                            /*&& (process.env.REACT_APP_TEST_MIGRATION_WITH_OS_4 === "true" ? userResponse.obra_social !== 4 : true)*/
                            if (
                                normalizedResponse.userType === UserTypes.OS_USER &&
                                !userResponse.allow_os_login
                            ) {
                                throw Error('NOT_ALLOWED');
                            }
                            const username = normalizedResponse.user.username;
                            computeUserType(
                                normalizedResponse.userType,
                                normalizedResponse.pubKey,
                                normalizedResponse.pubKeyData,
                                username,
                                resolveMigratedUserType,
                                normalizedResponse.branchPubkey,
                            )
                                .then(({ userType, oldPrivateKey, privateKey }) => {
                                    if (
                                        userType === UserTypes.OS_USER ||
                                        userType === UserTypes.AUDITOR ||
                                        userType === UserTypes.DRUGSTORE
                                    ) {
                                        if (!privateKey) {
                                            const mnemonic = bip39.entropyToMnemonic(oldPrivateKey);
                                            const keyManager = new KeyManager(username, mnemonic);
                                            const privateKey = keyManager.deriveDataKey();
                                            const publicKey = keyManager.getPublicKey(privateKey);
                                            dispatch(
                                                actions.session.loginResponse(
                                                    createLoginResponse(
                                                        userResponse,
                                                        userType,
                                                        privateKey,
                                                    ),
                                                ),
                                            );
                                            if (userType === UserTypes.ROCHE_USER) {
                                                dispatch(actions.session.getIsWhiteLister());
                                            }
                                            if (userType === UserTypes.DRUGSTORE) {
                                                dispatch(actions.patient.getPatients());
                                            }
                                            const fundsKey = keyManager.deriveFundsKey();
                                            dispatch(
                                                actions.profile.updatePublicKey(
                                                    { private: privateKey, public: publicKey },
                                                    keyManager.getAddress(
                                                        keyManager.getPublicKey(fundsKey),
                                                    ),
                                                    keyManager.mnemonic,
                                                ),
                                            );
                                            dispatch(
                                                actions.profile.saveOldPrivateKey(oldPrivateKey),
                                            );
                                            if (!normalizedResponse.privateKeyBackup) {
                                                dispatch(
                                                    actions.profile.savePKToCloud(
                                                        keyManager.mnemonic,
                                                    ),
                                                );
                                            }
                                        } else {
                                            const keyManager = new KeyManager(username);
                                            dispatch(
                                                actions.session.loginResponse(
                                                    createLoginResponse(
                                                        userResponse,
                                                        userType,
                                                        keyManager.deriveDataKey(),
                                                    ),
                                                ),
                                            );
                                            if (userType === UserTypes.DRUGSTORE) {
                                                dispatch(actions.patient.getPatients());
                                            }
                                            if (!normalizedResponse.privateKeyBackup) {
                                                dispatch(
                                                    actions.profile.savePKToCloud(
                                                        keyManager.mnemonic,
                                                    ),
                                                );
                                            }
                                        }
                                        if (oldPrivateKey) {
                                            dispatch(
                                                actions.profile.saveOldPrivateKey(oldPrivateKey),
                                            );
                                        }
                                        dispatch(actions.session.getNotifications());
                                    } else {
                                        dispatch(
                                            actions.session.loginResponse(
                                                createLoginResponse(userResponse, userType),
                                            ),
                                        );
                                        if (userType === UserTypes.ROCHE_USER) {
                                            dispatch(actions.session.getIsWhiteLister());
                                        }
                                        dispatch(actions.session.getNotifications());
                                    }
                                })
                                .catch(() => {
                                    dispatch(
                                        actions.session.loginResponseError(translateError('')),
                                    );
                                });
                        }
                    })
                    .catch((error) => {
                        dispatch(
                            actions.session.loginResponseError(
                                translateError(error?.data?.detail || error?.message),
                            ),
                        );
                    });
                break;
            case RESTORE_PASSWORD:
                services
                    .restorePassword(action.email)
                    .then((response) => dispatch(actions.session.restorePasswordResponse(response)))
                    .catch((error) =>
                        dispatch(
                            actions.session.restorePasswordError(translateError(error.details)),
                        ),
                    );
                break;
            case RESET_PASSWORD:
                services
                    .resetPassword(action.uid, action.token, action.password, action.passwordRepeat)
                    .then((response) => dispatch(actions.session.resetPasswordResponse(response)))
                    .catch((error) =>
                        dispatch(actions.session.resetPasswordError(translateError(error.details))),
                    );
                break;
            case GET_NOTIFICATIONS:
                services
                    .getNotifications()
                    .then((response) =>
                        dispatch(actions.session.getNotificationsResponse(response)),
                    )
                    .catch((error) =>
                        dispatch(
                            actions.session.getNotificationsError(translateError(error.details)),
                        ),
                    );
                break;
            case LOGOUT:
                _clearToken();
                dispatch(push('/'));
                break;
            case GET_IS_WHITE_LISTER:
                var { address, fundsKey } = getKeysFromKeystore(getState().profile.user.username);
                service
                    .addressIsWhiteLister(address, fundsKey)
                    .then(() => {
                        dispatch(actions.session.getIsWhiteListerResponse());
                    })
                    .catch(() => {
                        dispatch(actions.session.getIsWhiteListerError());
                    });
                break;
            default:
        }
    };

const userLoginService = (email, password, dispatch) => {
    return services
        .login(email, password)
        .then((loginResponse) => {
            _saveToken({ access: loginResponse.access, refresh: loginResponse.refresh });
            return services.getUser();
        })
        .catch((error) => {
            dispatch(actions.session.loginResponseError(translateError(error?.data?.detail)));
        });
};

const getKeyManagerPrivateKey = (username, pubKey) => {
    // Temporal solution until library can read and not modify
    return keysStorage
        .getMnemonicFromKeystore(username)
        .then((mnemonic) => {
            if (mnemonic && mnemonic !== '') {
                const keyManager = new KeyManager(username, mnemonic, true);
                const privateKey = keyManager.deriveDataKey();
                if (keyManager.getPublicKey(privateKey) === pubKey) return privateKey;
                else return undefined;
            } else return undefined;
        })
        .catch((e) => undefined);
};
const computeUserType = (
    userTypeGivenAtResponse,
    pubKey,
    pubKeyData,
    username,
    resolveUser,
    branchPubKey,
) => {
    return new Promise((resolve, reject) => {
        switch (userTypeGivenAtResponse) {
            case UserTypes.OS_USER:
                resolveUser(
                    resolve,
                    username,
                    pubKeyData,
                    UserTypes.OS_USER_WITHOUT_PUBLIC_KEY,
                    UserTypes.OS_USER_WITHOUT_PRIVATE_KEY,
                    UserTypes.OS_USER,
                    pubKey,
                    UserTypes.OS_USER_WITHOUT_OLD_PRIVATE_KEY,
                );
                break;
            case UserTypes.DRUGSTORE:
                resolveUser(
                    resolve,
                    username,
                    pubKeyData,
                    UserTypes.DRUGSTORE_WITHOUT_PUBLIC_KEY,
                    UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY,
                    UserTypes.DRUGSTORE,
                    pubKey,
                    UserTypes.DRUGSTORE_WITHOUT_OLD_PRIVATE_KEY,
                );
                break;
            case UserTypes.REGISTER_USER:
                if (!branchPubKey || branchPubKey === '') {
                    resolve({ userType: UserTypes.REGISTER_USER_WITHOUT_PUBLIC_KEY });
                } else {
                    keysStorage
                        .getPrivateByUsername(username)
                        .then((privateKey) => {
                            // If the branch has a public key check if the user has a private key on local storage
                            if (!privateKey)
                                resolve({ userType: UserTypes.REGISTER_USER_WITHOUT_PRIVATE_KEY });
                            else
                                resolve({
                                    userType: UserTypes.REGISTER_USER,
                                    oldPrivateKey: privateKey,
                                });
                        })
                        .catch((error) => {
                            console.error(
                                'The private key could not be retrieve from local storage. Error: ',
                                error,
                            );
                            reject(error);
                        });
                }
                break;
            case UserTypes.A_AUDITOR: // BACK Changed and now sends this type, not sure what it signifies
                resolveUser(
                    resolve,
                    username,
                    pubKeyData,
                    UserTypes.AUDITOR_WITHOUT_PUBLIC_KEY,
                    UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY,
                    UserTypes.AUDITOR,
                    pubKey,
                    UserTypes.AUDITOR_WITHOUT_OLD_PRIVATE_KEY,
                );
                break;
            case UserTypes.USER:
                resolve({ userType: UserTypes.USER });
                break;
            case UserTypes.ROCHE_USER:
                keysStorage
                    .getMnemonicFromKeystore(username)
                    .then((mnemonic) => {
                        if (!mnemonic) {
                            resolve({ userType: UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY }); //if it doesnt have mnemonic it doesnt have pk
                        } else {
                            const keyManager = new KeyManager(username, mnemonic, true);
                            const privateKey = keyManager.deriveDataKey();
                            resolve({ userType: UserTypes.ROCHE_USER, privateKey });
                        }
                    })
                    .catch((error) => {
                        console.error(
                            'The mnemonic words could not be retrieve from local storage. Error: ',
                            error,
                        );
                        reject(error);
                    });
                keysStorage
                    .getPrivateByUsername(username)
                    .then((privateKey) => {
                        if (!privateKey)
                            resolve({ userType: UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY });
                        else resolve({ userType: UserTypes.ROCHE_USER, privateKey });
                    })
                    .catch((error) => {
                        console.error(
                            'The private key could not be retrieve from local storage. Error: ',
                            error,
                        );
                        reject(error);
                    });
                break;
            default:
                resolve(userTypeGivenAtResponse);
        }
    });
};

const resolveMigratedUserType = (
    resolve,
    username,
    pubKeyData,
    userTypeWithoutPublicKey,
    userTypeWithoutPrivateKey,
    userType,
    pubKey,
    userTypeWithoutOldPrivateKey,
) => {
    if ((!pubKey || pubKey === '') && (!pubKeyData || pubKeyData === ''))
        resolve({ userType: userTypeWithoutPublicKey });
    else {
        if (!pubKeyData && pubKey) {
            keysStorage.getPrivateByUsername(username).then((oldPrivateKey) => {
                if (!oldPrivateKey) {
                    resolve({ userType: userTypeWithoutOldPrivateKey });
                } else {
                    resolve({ userType: userType, oldPrivateKey: oldPrivateKey });
                }
            });
        } else if (pubKeyData) {
            getKeyManagerPrivateKey(username, pubKeyData).then((privateKey) => {
                keysStorage.getPrivateByUsername(username).then((oldPrivateKey) => {
                    if (!oldPrivateKey && !privateKey) {
                        resolve({ userType: userTypeWithoutPrivateKey });
                    } else {
                        resolve({
                            userType: userType,
                            oldPrivateKey: oldPrivateKey,
                            privateKey: privateKey,
                        });
                    }
                });
            });
        } else {
            resolve({ userType: userTypeWithoutPublicKey });
        }
    }
};

const resolveUserType = (
    resolve,
    username,
    pubKeyData,
    userTypeWithoutPublicKey,
    userTypeWithoutPrivateKey,
    userType,
) => {
    if (!pubKeyData || pubKeyData === '') resolve({ userType: userTypeWithoutPublicKey });
    else {
        getKeyManagerPrivateKey(username, pubKeyData).then((privateKey) => {
            if (privateKey) {
                resolve({ userType: userType, privateKey });
            } else {
                resolve({ userType: userTypeWithoutPrivateKey });
            }
        });
    }
};

const normalizeUserResponse = (response) => ({
    id: response.id,
    user: response.user,
    orgId: response?.obra_social || response?.drogueria || response?.grupo_auditor?.id,
    orgName:
        response?.obra_social_descr || response?.drogueria_descr || response?.grupo_auditor_descr,
    userType: response.tipo_usuario,
    pubKey: response?.obra_social_pubkey || response?.grupo_auditor_pubkey,
    pubKeyData:
        response?.obra_social_pubkey_data ||
        response?.drogueria_pubkey_data ||
        response?.grupo_auditor_pubkey_data,
    encKey:
        response?.obra_social_enckey ||
        response?.drogueria_enckey ||
        response?.grupo_auditor_enckey,
    emailRecoverKey: response.email_recover_key,
    phone: response.telefono,
    privateKeyBackup: response.privkey_backup,
    acceptTyc: response.acepto_tyc,
    branchPubkey: response.sucursal?.pubkey,
});

const createLoginResponse = (response, userType, privateKey) => ({
    user: {
        id: response.user.id,
        email: response.user.email,
        first_name: response.user.first_name,
        last_name: response.user.last_name,
        phone: response.telefono,
        private_key_active: response.privkey_activa,
        privateKey: privateKey,
        username: response.user.username,
        type: userType,
        hasAcceptedTermsAndConditions:
            userType === UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY ? true : response.acepto_tyc,
        groups: response.user.groups,
        audit_group: response.grupo_auditor,
    },
    drug_id: response.drogueria,
    drug_name: response.drogueria_descr,
    drug_publicKey: response.drogueria_pubkey_data,
    os_id: response.obra_social,
    os_name: response.obra_social_descr,
    os_publicKey: response.obra_social_pubkey_data || response.obra_social_pubkey,
    encKey:
        response.obra_social_enckey ||
        response.grupo_auditor?.encryption_key ||
        response.drogueria_enckey,
    pk_backup: response.privkey_backup,
    email_recover_key: response.email_recover_key,
    migrated: response.migrated_nexus2,
    directPayment: response.pago_directo,
    branch: !response.sucursal
        ? {}
        : {
              id: response.sucursal.id,
              address: response.sucursal.direccion,
              phone: response.sucursal.telefono,
              publicKey: response.sucursal.pubkey,
              institution: response.sucursal.institucion,
          },
    institution:
        !response.sucursal || !response.sucursal.institucion
            ? {}
            : {
                  id: response.sucursal.institucion.id,
                  name: response.sucursal.institucion.razon_social,
                  cuit: response.sucursal.institucion.cuit,
                  type: parseInstitutionType(response.sucursal.institucion.tipo),
              },
});

// todo review error logic with Leandro.
const translateError = (errorCode) => {
    // this array is used because i18n doesn't accept some default translation value. Is used to show a generic error
    // if the error is not translated.
    let error = errorCode;

    switch (errorCode) {
        case 'No active account found with the given credentials':
            error = 'WRONG_CREDENTIALS';
            break;
    }

    const translatedErrors = [
        'NO_ACTIVE_ACCOUNT',
        'WRONG_CREDENTIALS',
        'EMAIL_NOT_FOUND',
        'NOT_ALLOWED',
    ];
    if (translatedErrors.includes(error)) return I18n.t(`errors.session.${error}`);
    return I18n.t(`errors.generic`);
    //return errorCode[`${Object.keys(errorCode)[0]}`][0];
};

export default sessionMiddleware;
