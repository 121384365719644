import { post } from './https';
import { _saveToken } from './interceptor';

const postRefreshToken = (refreshToken: string) =>
    post<any>('auth/jwt/refresh/', { refresh: refreshToken });

export const newRefreshToken = async () => {
    const refreshToken = localStorage['token-refresh'];
    try {
        const response = await postRefreshToken(refreshToken);
        console.log('refresh response', response);
        if (response.access) {
            _saveToken({
                access: response.access,
                refresh: refreshToken,
            });
        }
        return response;
    } catch (e) {
        console.log('refresh error', e);
    }
};
