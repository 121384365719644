import { get, post, put } from '../utils/axios/https';

export default {
    getPatients: () => get(`api/paciente/`),
    newPatient: (body) => post(`api/paciente/`, body),
    editPatient: (patientId, body) => put(`api/paciente/${patientId}/`, body),
    getProgramedDose: (id) => get(`api/pacientecontrato/${id}/dosisprogramadas`),
    getAuditorProgramedDose: (auditorId, id) =>
        get(`api/auditoria/${auditorId}/pacientecontrato/${id}/dosisprogramadas`),
    getFileToUploadUrl: (id, name) => get(`api/pacientecontrato/${id}/adjunto/${name}/uploadurl`),
    uploadFileToS3: (url, body) => put(url, body, { headers: { 'Content-Type': body.type } }, true),
    getFileToS3: (url) => get(url, true),
    getBasicPatients: () => get('api/paciente/basico/'),
};
