import React from 'react';
import { connect } from 'react-redux';
import OSDashboard from './os-dashboard/OSDashboard';
import RocheDashboard from './roche-dashboard/RocheDashboard';
import actions from '../actions';
import { default as DashboardOld } from './containers/Dashboard';
import { UserTypes } from '../constants';
import MigrationModal from './components/MigrationModal/MigrationModal';

interface DashboardProps {
    migrationStatus: any;
    userType: any;
    osMigrated: any;
    osId: any;
    migratePatients: () => void;
    logout: () => void;
}

const Dashboard = (props: DashboardProps) => {
    const isOsUser = [
        UserTypes.OS_USER,
        UserTypes.OS_USER_WITHOUT_PUBLIC_KEY,
        UserTypes.OS_USER_WITHOUT_PRIVATE_KEY,
        UserTypes.OS_USER_WITHOUT_OLD_PRIVATE_KEY,
    ].includes(props.userType);

    const isRocheUser = [UserTypes.ROCHE_USER, UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY].includes(
        props.userType,
    );

    const renderDashboard = () => {
        if (isOsUser) return <OSDashboard />;
        if (isRocheUser) return <RocheDashboard />;
        else return <DashboardOld />;
    };

    return (
        <>
            {renderDashboard()}
            <MigrationModal
                migrationStatus={props.migrationStatus}
                migratePatients={props.migratePatients}
                userType={props.userType}
                osMigrated={props.osMigrated}
                logout={props.logout}
            />
        </>
    );
};

const mapStateToProps = (state: any) => ({
    migrationStatus: state.patient.patientMigrationStatus,
    userType: state.profile.user.type,
    osMigrated: state.session.migrated,
    osId: state.session.os_id,
});

const mapDispatchToProps = (dispatch: any) => ({
    migratePatients: () => dispatch(actions.patient.patientMigration()),
    logout: () => dispatch(actions.session.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
